<template>
  <div class="d-flex justify-content-center align-items-center" style="min-height:100svh">
    <b-card
      style="max-width: 40rem;"
      class="w-100 p-5 shadow-sm"
    >
      <img alt="logo" src="../../assets/launchyou.jpg" class="mb-5 img-fluid">
      <b-form @submit="onSubmit">
        <b-form-group
          label="Email address:"
          label-for="email"
        >
          <b-form-input
            v-model="form.email"
            type="email"
            placeholder="Enter email"
          ></b-form-input>
          <b-alert show variant="warning" v-if="errors.email">{{ errors.email }}</b-alert>
        </b-form-group>

        <b-form-group label="Password:" label-for="password">
          <b-form-input
            type="password"
            v-model="form.password"
            placeholder="Enter password"
          ></b-form-input>
          <b-alert show variant="warning" v-if="errors.password">{{ errors.password }}</b-alert>
        </b-form-group>
        <br/>
        <b-button type="submit" variant="primary">Submit</b-button>
      </b-form>
      <br />
      <div>
      <b-alert show variant="danger" v-if="showError">{{ errorMessage }}</b-alert>
      </div>
      <br />
      <div><a href="/forgot-password">Forgot Password?</a></div>
    </b-card>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  data() {
    return {
      form: {
        email: '',
        password: '',
      },
      showError: false,
      errorMessage: '',
      errors: {email: null, password: null},
    }
  },
  beforeCreate: function () {
    if(localStorage.getItem('user') != null) {
      //window.location.href = '/recordings';
      this.$router.push('/recordings')
    }
  },
  methods: {
    ...mapActions(['login']),
    onSubmit(event) {
      event.preventDefault()
      this.showError = false;
      let email = this.form.email
      let password = this.form.password

      this.errors = {};

      if (email === '') {
        this.errors.email = 'Email is required.';
      }

      if (password === '') {
        this.errors.password = 'Password is required.';
      }

      if (Object.keys(this.errors).length > 0) {
        return false;
      }

      this.login({email, password})
        .then((response) => {
          //this.$router.push('/recordings')
        //  if(response.data.auth_token) {
          //  this.$router.push('/recordings')
if(response.data.user) {
  window.location.href = '/recordings';
}
        //  }
        })
        .catch(error => {

          this.showError = true;

          this.errorMessage = error.response.data.error
        });
    }
  }
}
</script>

